import { useCallback, useRef } from 'react'
import { useConstant } from '@/hooks/useConstant'

type BouncerRef<TFn> = {
  id: number
  fn?: TFn
  promise?: Promise<Awaited<TFn>>
  timeout?: NodeJS.Timeout
  resolve?: (value: Awaited<TFn>) => void
  reject?: (value: unknown) => void
}

/**
 * Credit: useBouncer hook by @tannerlinsley.
 * See the JS version: https://codesandbox.io/s/dazzling-rain-ri4em
 */
export const useAsyncDebounce = <TFn, TArgs extends unknown[]>(
  fn: (...args: TArgs) => TFn,
  delay = 0
) => {
  const ref = useRef<BouncerRef<TFn>>({ id: 0 })
  const bouncerFn = useConstant(fn)

  // Create the debounced function
  return useCallback(
    // Proxy all arguments to our debounced function
    (...args: TArgs) => {
      // Create a new promise
      ref.current.promise = new Promise((resolve, reject) => {
        // Keep track of resolve and reject
        ref.current.resolve = resolve
        ref.current.reject = reject
      })

      // Clear the old timeout if one exists!
      if (ref.current.timeout) {
        clearTimeout(ref.current.timeout)
      }

      ref.current.timeout = setTimeout(async () => {
        ref.current.timeout = undefined
        // Get a new ID for this async request
        const id = ref.current.id + 1

        // Update the hook with the latest request ID
        ref.current.id = id

        // Make a check latest id function
        const checkLatest = () => id === ref.current.id

        try {
          // Run the debounced function
          const response = await bouncerFn(...args)
          // If the request is latest, resolve
          if (checkLatest()) ref.current.resolve?.(response)
        } catch (err) {
          // If the request is latest, reject
          if (checkLatest()) ref.current.reject?.(err)
        }
      }, delay)

      // Always return the promise!
      return ref.current.promise
    },
    [delay, bouncerFn]
  )
}
