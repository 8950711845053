import debounce from 'lodash/debounce'
import { useCallback, useEffect } from 'react'

export const useLiveAnnouncer = () => {
  const getLiveAnnouncerNode = () => {
    let node = document.getElementById('live-announcer')
    if (node) return node

    node = document.createElement('div')
    node.setAttribute('id', 'live-announcer')
    node.setAttribute('role', 'status')
    node.setAttribute('aria-live', 'polite')
    Object.assign(node, {
      border: '0',
      clip: 'rect(0 0 0 0)',
      height: '1px',
      margin: '-1px',
      overflow: 'hidden',
      padding: '0',
      position: 'absolute',
      whiteSpace: 'nowrap',
      width: '1px',
    })
    document.body.appendChild(node)
    return node
  }

  const setLiveAnnouncer = useCallback((message: string) => {
    if (!message) return

    const node = getLiveAnnouncerNode()
    node.textContent = message ?? ''
    debounce(() => {
      node.textContent = ''
    }, 500)
  }, [])

  useEffect(() => {
    return () => {
      const node = getLiveAnnouncerNode()
      if (node) document.body.removeChild(node)
    }
  }, [])

  return { setLiveAnnouncer }
}
