import { useCallback, useState } from 'react'
import {
  fetchAutocompleteSuggestions,
  fetchCurrentLocation,
  fetchGeoLocationByIp,
} from '@/services/GeoLocation/fetchers'
import { navigatorGetCurrentPosition } from '@/services/GeoLocation/utils'
import { type GeoCoordinates } from '@/services/GeoLocation/types'
import { getQueryClient } from '@/utils/dataFetching/reactQuery/SegwayQueryClient'
import { useQuery } from '@tanstack/react-query'
import { type ValidAddress } from '@/services/Addresses/types'

const getReverseGeocodeQueryKey = (coordinates: GeoCoordinates) =>
  [ReverseGeocodeQuery, coordinates] as const
const ReverseGeocodeQuery = 'Reverse geocode'
export type ReverseGeocodeQueryKey = ReturnType<
  typeof getReverseGeocodeQueryKey
>

export const useFetchCurrentLocationQuery = () => {
  const queryClient = getQueryClient()
  const [isLoading, setIsLoading] = useState(false)

  const fetchCurrentLocationQuery =
    useCallback(async (): Promise<ValidAddress> => {
      setIsLoading(true)
      try {
        const currentPosition = await navigatorGetCurrentPosition()
        const { latitude, longitude } = currentPosition?.coords ?? {}
        const data = await queryClient.fetchQuery({
          queryKey: getReverseGeocodeQueryKey({ latitude, longitude }),
          queryFn: fetchCurrentLocation,
        })
        return {
          street1: data?.street1 ?? '',
          city: data?.city ?? '',
          state: data?.state ?? '',
          zip_code: data?.zip_code ?? '',
        }
      } finally {
        setIsLoading(false)
      }
    }, [queryClient])

  return { isLoading, fetchCurrentLocationQuery }
}

const AutocompleteSuggestionsQuery = 'Autocomplete suggestions'
const getAutocompleteSuggestionsQueryKey = (query: string) =>
  [AutocompleteSuggestionsQuery, { query }] as const
export type AutocompleteSuggestionsQueryKey = ReturnType<
  typeof getAutocompleteSuggestionsQueryKey
>

export const useQueryAutocompleteSuggestions = (
  query: string,
  sessionToken: string
) => {
  return useQuery({
    queryKey: getAutocompleteSuggestionsQueryKey(query),
    queryFn: (context) => fetchAutocompleteSuggestions(context, sessionToken),
    enabled: Boolean(query),
    staleTime: Infinity,
    placeholderData: (previousData) => previousData,
  })
}

export const useQueryGeoLocationByIp = ({
  ip,
  enabled,
}: {
  ip: string
  enabled: boolean
}) => {
  return useQuery({
    queryKey: ['Geo location', ip],
    queryFn: () => fetchGeoLocationByIp(ip),
    enabled: Boolean(enabled),
    retryOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  })
}
